import axios from '../libs/axios';

// post
const postAction = (url, parameter) => {
  return axios.post(
    url,
    parameter

  );
};

// post method= {post | put}
const httpAction = (url, parameter, method) => {
  return axios.request({
    url,
    method,
    data: parameter
  });
};

// put
const putAction = (url, parameter) => {
  return axios.put(
    url,
    parameter

  );
};

const getAction = (url, parameter) => {
  // console.log('getAction');
  return axios.get(url, parameter);
};
// deleteAction
const deleteAction = (url, parameter) => {
  return axios.delete(url, parameter);
};
// 加载分类字典
const loadCategoryData = (params) => {
  return axios.get('/api/sys/category/loadAllData', params);
};
const loadCategoryCarData = (params) => {
  return axios.get('/api/sys/car/category/loadAllData', params);
};
const loadCategoryDictData = (params) => {
  return axios.get('/api/sys/dict/category/loadAllData', params);
};
// 数据字典
const addDict = (params) => {
  axios.post('/api/sys/dict/dictadd', params);
};
const editDict = (params) => {
  axios.put('/api/sys/dict/dictedit', params);
};
const treeList = (params) => { axios.get('/api/sys/dict/treeList', params); };
const addDictItem = (params) => { axios.post('/api/sys/dictItem/add', params); };
const editDictItem = (params) => { axios.put('/api/sys/dictItem/edit', params); };
// 重复校验
const duplicateCheck = (params) => {
  axios.get('/api/sys/duplicate/check', params);
};
// 字典标签专用（通过code获取字典数组）
const ajaxGetDictItems = (code, params) => { return axios.get(`/api/sys/dict/getDictItems/${code}`, params); };
/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
function downFile(url, parameter) {
  return axios.get({
    url,
    parameter,
    responseType: 'blob'
  });
}
/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
const downloadFile = (url, fileName, parameter) => {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0) {
      // Vue.prototype.$message.warning('文件下载失败');
      return;
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName);
    } else {
      const urls = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = urls;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(urls); // 释放掉blob对象
    }
  });
};

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
const uploadAction = (url, parameter) => {
  return axios.post({
    url,
    parameter,
    headers: {
      'Content-Type': 'multipart/form-data', // 文件上传
    },
  });
};

/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
const getFileAccessHttpUrl = (avatar, subStr) => {
  console.log(avatar);
  if (!subStr) subStr = 'http';
  try {
    if (avatar && avatar.startsWith(subStr)) {
      return avatar;
    }
    if (avatar && avatar.length > 0 && avatar.indexOf('[') === -1) {
      return `${window._CONFIG.staticDomainURL}/${avatar}`;
    }
  } catch (err) {
    console.log(err);
  }
};
export {
  deleteAction,
  httpAction,
  getAction,
  postAction,
  putAction,
  loadCategoryData,
  loadCategoryCarData,
  ajaxGetDictItems,
  getFileAccessHttpUrl,
  uploadAction,
  downloadFile,
  addDict,
  editDict,
  treeList,
  addDictItem,
  editDictItem,
  duplicateCheck,
  loadCategoryDictData
};
